<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardText>
            <VForm
              ref="form"
            >
              <VContainer fluid>
                <VRow
                  align="center"
                >
                  <VCol md="6">
                    <div>
                      <strong>Имя</strong>
                    </div>
                    <VTextField
                      v-model="entity.name"
                    />
                    <template v-for="token in tokensArray">
                      <div :key="token.name + 'title'">
                        <strong>{{ token.name }}</strong>
                      </div>
                      <VTextField
                        :key="token.name"
                        v-model="token.token"
                      />
                    </template>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol md="4">
                    <VBtn
                      large
                      depressed
                      color="primary"
                      class="mr-3"
                      @click="save"
                    >
                      Сохранить
                    </VBtn>
                    <VBtn
                      large
                      text
                      depressed
                      @click="$router.push({ name : Names.R_ACCOUNT_SYS_COMPANIES })"
                    >
                      Отмена
                    </VBtn>
                  </VCol>
                </VRow>
              </VContainer>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>

export default {
  name: 'SysCompanyItemEdit',
  inject: ['Names'],
  data() {
    return {
      entity: {},
      tokens: {
        slack: {
          token: '',
          id: 0,
          name: 'Токен для бота Slack',
        },
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    tokensArray() {
      return Object.values(this.tokens).map((value) => value);
    },
  },
  created() {
    this.$di.api.Account.getCompany({ id: this.id })
      .then((r) => {
        this.entity = { ...this.entity, ...r.company };
      })
      .catch((err) => {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      });
    this.$di.api.NotificationBots.getBotTokens({ companyId: this.id })
      .then((r) => {
        r.bots?.forEach((bot) => {
          this.tokens[bot.botType] = {
            ...this.tokens[bot.botType],
            id: bot.id,
            token: bot.token,
          };
        });
      });
  },
  methods: {
    save() {
      const { entity } = this;
      const { active, accountId, ...rest } = entity;
      this.$di.api.Account.updateCompany({ company: rest })
        .then(() => Promise.all(this.getDeleteTokenPromises()))
        .then(() => Promise.all(this.getAddTokenPromises()))
        .then(() => this.$di.notify.snackSuccess('Компания успешно обновлена'))
        .catch(this.$di.notify.errorHandler);
    },
    getDeleteTokenPromises() {
      const deleteTokenPromises = [];
      Object.entries(this.tokens).forEach(([key, value]) => {
        if (value.id) {
          deleteTokenPromises.push(
            new Promise((resolve) => {
              this.$di.api.NotificationBots.deleteBotToken({
                id: value.id,
              })
                .then(() => {
                  this.tokens[key].id = undefined;
                  resolve();
                });
            }),
          );
        }
      });
      return deleteTokenPromises;
    },
    getAddTokenPromises() {
      const addTokenPromises = [];
      Object.entries(this.tokens).forEach(([key, value]) => {
        if (value.token) {
          addTokenPromises.push(
            new Promise((resolve) => {
              this.$di.api.NotificationBots.addBotToken({
                companyId: this.id,
                token: value.token,
                botType: key,
              })
                .then((r) => {
                  this.tokens[key].id = r.id;
                  this.tokens[key].token = value.token;
                  resolve();
                });
            }),
          );
        }
      });
      return addTokenPromises;
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
  .dark {
    border-color: orange !important;
    border-width: 2px;
  }
</style>
